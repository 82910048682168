// import { appBackendAppgainUrl, getConfigHeaders } from "../api";
import toast from "react-hot-toast";
import IkhairServerInstance from "../axios/ikhairInstance";
import { is } from "immutable";
import toaster from "../modules/toaster";
import { globalConfig } from "antd/lib/config-provider";

const defaultUserInfo = {
  name: "Appgain",
  image:
    "https://www.buckinghamandcompany.com.au/wp-content/uploads/2016/03/profile-placeholder.png",
};

const AUTH_PENDING = "AUTH_PENDING";
const USER_LOGIN = "USER_LOGIN";
const LOGIN_ERROR = "LOGIN_ERROR";
const generate_OTP = "generate_OTP";
const VerifiyOTP = "VerifiyOTP";
const UserID = "UserID";
const LogOut = "LogOut";

// Generate OTP
export const generat_Otp = (mobileNumber, email) => async (dispatch) => {
  dispatch({ type: AUTH_PENDING });

  const data_sent = mobileNumber
    ? { mobile_number: mobileNumber }
    : { email: email };

  try {
    const data = await IkhairServerInstance.post("/otp/generate", data_sent);
    dispatch({
      type: generate_OTP,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: generate_OTP,
      payload: error,
    });
    throw error; // Rethrow the error
  }
};

// verify OTP
export const verifyOTPFunc =
  (otp, timestamp, token, mobile_number, email) => async (dispatch) => {
    dispatch({ type: AUTH_PENDING });

    const payload = {
      otp,
      timestamp,
    };

    if (mobile_number) {
      payload.mobile_number = mobile_number;
    }

    if (email) {
      payload.email = email;
    }

    try {
      const response = await IkhairServerInstance.post("/otp/verify", payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response);
      const { access_token, object_id } = response.data;
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("objectId", object_id);
      globalConfig.AuthToken = access_token;
      globalConfig.XObjectId = object_id;

      dispatch({
        type: VerifiyOTP,
        payload: response.data,
      });
    } catch (error) {
      if (error?.response?.status === 400) {
        toast.error(error?.response?.data?.message);
      }
    }
  };

// get user when verified
export const getUserData = (userId) => async (dispatch) => {
  dispatch({ type: AUTH_PENDING });
  const token = localStorage.getItem("access_token");

  try {
    const response = await IkhairServerInstance.get(`/users/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      if (response?.data.role === "admin") {
        localStorage.setItem("userloggedin", true);
      } else {
        localStorage.setItem("userloggedin", false);

        toast.error("Only admin can loggin");
      }
    }

    dispatch({
      type: UserID,
      payload: response.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const validateUser = (auth, userObjectId) => async (dispatch) => {
  dispatch({ type: AUTH_PENDING });

  try {
    const response = await IkhairServerInstance.post(
      "/token/verify",
      {},
      {
        headers: {
          Authorization: `Bearer ${auth}`,
          "X-Object-Id": userObjectId,
        },
      }
    );
    if (response.status === 200) {
      if (response?.data.role === "admin") {
        localStorage.setItem("userloggedin", true);
      } else {
        localStorage.setItem("userloggedin", false);
      }
    }
    dispatch({
      type: USER_LOGIN,
      payload: response.data,
    });
  } catch (error) {
    console.log(error.response);
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.reload(false);
    }
  }
};

export const Logout = () => async (dispatch) => {
  const token = localStorage.getItem("access_token");
  const userId = localStorage.getItem("objectId");

  try {
    const response = await IkhairServerInstance.post(
      "/token/revoke",
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-Object-Id": userId,
        },
      }
    );
    dispatch({
      type: LogOut,
      payload: response.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export default function reducer(
  state = {
    user: defaultUserInfo,
    isPending: false,
    error: null,
    isLoggedIn: false,
    generateOTP: [],
    verify_OTp: [],
    user_Data: [],
    logout: [],
  },
  action
) {
  switch (action.type) {
    case AUTH_PENDING:
      return {
        ...state,
        isPending: true,
      };
    case USER_LOGIN:
      return {
        ...state,
        user: action.payload,
        isLoggedIn: true,
        isPending: false,
        error: null,
      };

    case LOGIN_ERROR:
      return {
        ...state,
        error: action.error,
        isPending: false,
      };
    case generate_OTP:
      return {
        ...state,
        isPending: false,
        generateOTP: action.payload,
        error: action.error,
      };
    case VerifiyOTP: {
      return {
        ...state,
        isPending: false,
        verify_OTp: action.payload,
        error: action.error,
      };
    }
    case UserID: {
      return {
        ...state,
        isPending: false,
        isLoggedIn: true,
        user_Data: action.payload,
        error: action.error,
      };
    }
    case LogOut: {
      return {
        ...state,
        isPending: false,
        logout: action.payload,
        error: action.error,
      };
    }

    default:
      return state;
  }
}
