import axios from "axios";
import { config as globalConfig } from "../config";
const defaultState = {
  bannars_Last_Added: [],
  bannars_Last_Updated: [],
  projects_Last_Added: [],
  projects_Last_Updated: [],
  cases_Last_Added: [],
  cases_Last_Updated: [],
  inMemory_Last_Added: [],
  inMemory_Last_Updated: [],
  error: null,
  isPending: null,
};

const BANNER_LAST_ADDED = "BANNER_LAST_ADDED";
const BANNER_LAST_UPDATED = "BANNER_LAST_UPDATED";
const PROJECT_LAST_ADDED = "PROJECT_LAST_ADDED";
const PROJECT_LAST_UPDATED = "PROJECT_LAST_UPDATED";
const CASE_LAST_ADDED = "CASE_LAST_ADDED";
const CASE_LAST_UPDATED = "CASE_LAST_UPDATED";
const INMEMORY_LAST_ADDED = "INMEMORY_LAST_ADDED";
const INMEMORY_LAST_UPDATED = "INMEMORY_LAST_UPDATED";
const LOAD_LIST_PENDING = "LOAD_LIST_PENDING";
const LOAD_OVERVIEW_LIST_ERROR = "LOAD_OVERVIEW_LIST_ERROR";

export const getBannerLastUpdated = () => async (dispatch) => {
  dispatch({ type: LOAD_LIST_PENDING, payload: true });

  try {
    const data = await axios.get(
      `${globalConfig.ikhair}/banners/stats/last_updated`
    );
    dispatch({ type: BANNER_LAST_UPDATED, payload: data.data });
  } catch (error) {
    dispatch({ type: LOAD_OVERVIEW_LIST_ERROR, payload: error });
  }
};
export const getBannerLastAdded = () => async (dispatch) => {
  dispatch({ type: LOAD_LIST_PENDING, payload: true });

  try {
    const data = await axios.get(
      `${globalConfig.ikhair}/banners/stats/last_added`
    );
    dispatch({ type: BANNER_LAST_ADDED, payload: data.data });
  } catch (error) {
    dispatch({ type: LOAD_OVERVIEW_LIST_ERROR, payload: error });
  }
};

export const getProjectLastAdded = () => async (dispatch) => {
  dispatch({ type: LOAD_LIST_PENDING, payload: true });

  try {
    const data = await axios.get(
      `${globalConfig.ikhair}/projects/stats/last_added`
    );
    dispatch({ type: PROJECT_LAST_ADDED, payload: data.data });
  } catch (error) {
    dispatch({ type: LOAD_OVERVIEW_LIST_ERROR, payload: error });
  }
};
export const getProjectLastUpdated = () => async (dispatch) => {
  dispatch({ type: LOAD_LIST_PENDING, payload: true });

  try {
    const data = await axios.get(
      `${globalConfig.ikhair}/projects/stats/last_updated`
    );
    dispatch({ type: PROJECT_LAST_UPDATED, payload: data.data });
  } catch (error) {
    dispatch({ type: LOAD_OVERVIEW_LIST_ERROR, payload: error });
  }
};
export const getCasesLastAdded = () => async (dispatch) => {
  dispatch({ type: LOAD_LIST_PENDING, payload: true });

  try {
    const data = await axios.get(
      `${globalConfig.ikhair}/causes/stats/last_added`
    );
    dispatch({ type: CASE_LAST_ADDED, payload: data.data });
  } catch (error) {
    dispatch({ type: LOAD_OVERVIEW_LIST_ERROR, payload: error });
  }
};
export const getCasesLastUpdated = () => async (dispatch) => {
  dispatch({ type: LOAD_LIST_PENDING, payload: true });

  try {
    const data = await axios.get(
      `${globalConfig.ikhair}/causes/stats/last_updated`
    );
    dispatch({ type: CASE_LAST_UPDATED, payload: data.data });
  } catch (error) {
    dispatch({ type: LOAD_OVERVIEW_LIST_ERROR, payload: error });
  }
};
export const getInMemoryLastUpdated = () => async (dispatch) => {
  dispatch({ type: LOAD_LIST_PENDING, payload: true });

  try {
    const data = await axios.get(
      `${globalConfig.appBackend}/classes/InMemory?order=-updatedAt&limit=1`,
      {
        headers: {
          "X-Parse-Application-Id": globalConfig.appId,
        },
      }
    );
    dispatch({ type: INMEMORY_LAST_UPDATED, payload: data.data.results[0] });
  } catch (error) {
    dispatch({ type: LOAD_OVERVIEW_LIST_ERROR, payload: error });
  }
};
export const getInMemoryLastAdded = () => async (dispatch) => {
  dispatch({ type: LOAD_LIST_PENDING, payload: true });

  try {
    const data = await axios.get(
      `${globalConfig.appBackend}/classes/InMemory?order=-createdAt&limit=1`,
      {
        headers: {
          "X-Parse-Application-Id": globalConfig.appId,
        },
      }
    );
    dispatch({ type: INMEMORY_LAST_ADDED, payload: data.data.results[0] });
  } catch (error) {
    dispatch({ type: LOAD_OVERVIEW_LIST_ERROR, payload: error });
  }
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case LOAD_LIST_PENDING:
      return {
        ...state,
        isPending: true,
        error: false,
      };
    case LOAD_OVERVIEW_LIST_ERROR:
      return {
        ...state,
        isPending: false,
        error: action.error,
      };
    case BANNER_LAST_UPDATED:
      return {
        ...state,
        bannars_Last_Updated: action.payload,
        isPending: false,
        error: false,
      };
    case BANNER_LAST_ADDED:
      return {
        ...state,
        bannars_Last_Added: action.payload,
        isPending: false,
        error: false,
      };
    case PROJECT_LAST_ADDED:
      return {
        ...state,
        projects_Last_Added: action.payload,
        isPending: false,
        error: false,
      };
    case PROJECT_LAST_UPDATED:
      return {
        ...state,
        projects_Last_Updated: action.payload,
        isPending: false,
        error: false,
      };
    case CASE_LAST_ADDED:
      return {
        ...state,
        cases_Last_Added: action.payload,
        isPending: false,
        error: false,
      };
    case CASE_LAST_UPDATED:
      return {
        ...state,
        cases_Last_Updated: action.payload,
        isPending: false,
        error: false,
      };
    case INMEMORY_LAST_UPDATED:
      return {
        ...state,
        inMemory_Last_Updated: action.payload,
        isPending: false,
        error: false,
      };
    case INMEMORY_LAST_ADDED:
      return {
        ...state,
        inMemory_Last_Added: action.payload,
        isPending: false,
        error: false,
      };
    default:
      return state;
  }
}
