import React, { useEffect } from "react";
import BannerInfo from "../../components/overviewCard/OvervView";
import "./overview.css";
import { connect } from "react-redux";
import {
  getBannerLastAdded,
  getBannerLastUpdated,
  getCasesLastAdded,
  getCasesLastUpdated,
  getInMemoryLastAdded,
  getInMemoryLastUpdated,
  getProjectLastAdded,
  getProjectLastUpdated,
} from "../../reducers/overview";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import dateFormatter from "../../helpers/dateFormatter";
import { timeFormatter } from "../../helpers/dateTimeFromatter";
import PropTypes from "prop-types";

function OverviewPage({
  bannars_Last_Updated,
  getBannerLastUpdated,
  getBannerLastAdded,
  getProjectLastAdded,
  getProjectLastUpdated,
  getCasesLastUpdated,
  getCasesLastAdded,
  getInMemoryLastUpdated,
  getInMemoryLastAdded,
  cases_Last_Added,
  cases_Last_Updated,
  project_Last_Updated,
  project_Last_Added,
  bannars_Last_Added,
  inMemory_last_updated,
  inMemory_Last_Added,
  isPending,
}) {
  useEffect(() => {
    getBannerLastUpdated();
    getBannerLastAdded();
    getProjectLastAdded();
    getProjectLastUpdated();
    getCasesLastUpdated();
    getCasesLastAdded();
    getInMemoryLastUpdated();
    getInMemoryLastAdded();
  }, []);

  if (isPending) {
    return <LoadingSpinner />;
  }

  return (
    <div className="overview-container">
      <h1 className="overview-title">Overview</h1>
      <div className="overview-divider"></div>

      <div className="overview-grid">
        <div className="overview-grid-item">
          <BannerInfo
            title="Banners"
            lastUpdatedDate={dateFormatter(
              bannars_Last_Updated?.last_updated_banner_date
            )}
            lastUpdatedTime={timeFormatter(
              bannars_Last_Updated?.last_updated_banner_date
            )}
            lastAddedDate={dateFormatter(
              bannars_Last_Added?.last_added_banner_date
            )}
            lastAddedTime={timeFormatter(
              bannars_Last_Added?.last_added_banner_date
            )}
          />
        </div>

        <div className="overview-grid-item">
          <BannerInfo
            title="Projects"
            lastUpdatedDate={dateFormatter(
              project_Last_Updated?.last_updated_project_date
            )}
            lastUpdatedTime={timeFormatter(
              project_Last_Updated?.last_updated_project_date
            )}
            lastAddedDate={dateFormatter(
              project_Last_Added?.last_added_project_date
            )}
            lastAddedTime={timeFormatter(
              project_Last_Added?.last_added_project_date
            )}
          />
        </div>

        <div className="overview-grid-item">
          <BannerInfo
            title="Cases"
            lastUpdatedDate={dateFormatter(
              cases_Last_Updated?.last_updated_cause_date
            )}
            lastUpdatedTime={timeFormatter(
              cases_Last_Updated?.last_updated_cause_date
            )}
            lastAddedDate={dateFormatter(
              cases_Last_Added?.last_added_cause_date
            )}
            lastAddedTime={timeFormatter(
              cases_Last_Added?.last_added_cause_date
            )}
          />
        </div>

        <div className="overview-grid-item">
          <BannerInfo
            title="In memory"
            lastUpdatedDate={dateFormatter(inMemory_last_updated?.updatedAt)}
            lastUpdatedTime={timeFormatter(inMemory_last_updated?.updatedAt)}
            lastAddedDate={dateFormatter(inMemory_Last_Added?.createdAt)}
            lastAddedTime={timeFormatter(inMemory_Last_Added?.createdAt)}
            pendingCount={4}
          />
        </div>
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    bannars_Last_Updated: state.overview.bannars_Last_Updated,
    bannars_Last_Added: state.overview.bannars_Last_Added,
    project_Last_Updated: state.overview.projects_Last_Updated,
    project_Last_Added: state.overview.projects_Last_Added,
    cases_Last_Added: state.overview.cases_Last_Added,
    cases_Last_Updated: state.overview.cases_Last_Updated,
    inMemory_last_updated: state.overview.inMemory_Last_Updated,
    inMemory_Last_Added: state.overview.inMemory_Last_Added,
    isPending: state.overview.isPending,
    isError: state.overview.error,
  }),
  (dispatch) => ({
    getBannerLastUpdated: () => dispatch(getBannerLastUpdated()),
    getBannerLastAdded: () => dispatch(getBannerLastAdded()),
    getProjectLastAdded: () => dispatch(getProjectLastAdded()),
    getProjectLastUpdated: () => dispatch(getProjectLastUpdated()),
    getCasesLastUpdated: () => dispatch(getCasesLastUpdated()),
    getCasesLastAdded: () => dispatch(getCasesLastAdded()),
    getInMemoryLastUpdated: () => dispatch(getInMemoryLastUpdated()),
    getInMemoryLastAdded: () => dispatch(getInMemoryLastAdded()),
  })
)(OverviewPage);

OverviewPage.propTypes = {
  bannars_Last_Updated: PropTypes.object,
  getBannerLastUpdated: PropTypes.func,
  getBannerLastAdded: PropTypes.func, // Add this line
  getProjectLastAdded: PropTypes.func,
  getProjectLastUpdated: PropTypes.func,
  getCasesLastUpdated: PropTypes.func,
  getCasesLastAdded: PropTypes.func,
  getInMemoryLastUpdated: PropTypes.func,
  getInMemoryLastAdded: PropTypes.func,
  cases_Last_Added: PropTypes.object,
  cases_Last_Updated: PropTypes.object,
  project_Last_Updated: PropTypes.object,
  project_Last_Added: PropTypes.object,
  bannars_Last_Added: PropTypes.object,
  inMemory_last_updated: PropTypes.object,
  inMemory_Last_Added: PropTypes.object,
  isPending: PropTypes.bool,
};
