import { Checkbox, TableCell, TableRow } from "@material-ui/core";
import "assets/styles/base.scss";
import Switch from "components/Switch";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { BiCopy } from "react-icons/bi";
import { FaRegEdit } from "react-icons/fa";
import { connect } from "react-redux";
import toaster from "../../../modules/toaster";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { IoAddCircleOutline } from "react-icons/io5";

import {
  createMPS,
  duplicateProject,
  editProjectStatus,
  loadProjectsList,
} from "../../../reducers/Projects";
import "./table.css";
import { Dropdown, Menu } from "antd";
class TableWithSwitch extends Component {
  state = {
    selected: [],
  };

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  getActivityName = (id) => {
    let name = id;
    if (this.props.activities && this.props.activities.length > 0)
      for (let act of this.props.activities)
        if (act.id == id) name = act.nameAR;
    return name;
  };

  getNGOName = (id) => {
    let name = id;
    if (this.props.orgs && this.props.orgs.length > 0)
      for (let org of this.props.orgs) if (org.id == id) name = org.nameAR;
    return name;
  };

  handleDuplicate = (project) => {
    this.props.duplicate(project).then(() => {
      this.nextPath("/projects/new?duplicate=true");
    });
  };

  toggleActive = (project) => {
    this.props
      .editStatus({
        id: project.id,
        new_status: project.isactive == 1 ? 0 : 1,
      })
      .then(() => {
        project.isactive = project.isactive == 1 ? 0 : 1;
        this.props.loadProjects(this.props.selectedOrg);
        toaster.success("Project's status has been changed successfully.");
      });
  };

  handleEdit = (project) => {
    this.props.duplicate(project).then(() => {
      this.nextPath("/projects/new?edit=true");
    });
  };

  nextPath(path) {
    this.context.router.history.push(path);
  }

  createMPs = (data) => {
    this.props.createMPsss({
      id: data.id,
      type: "project",
    });
  };

  render() {
    const menu = (
      <Menu>
        <Menu.Item>
          <div
            style={{ display: "flex", alignItems: "center", gap: "5px" }}
            onClick={() => {
              this.handleEdit(this.props.project);
              console.log(this.props.project);
            }}
          >
            <FaRegEdit size={24} color="#00AEEF" />
            <span>Edit Project</span>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div
            style={{ display: "flex", alignItems: "center", gap: "5px" }}
            onClick={() => {
              this.handleDuplicate(this.props.project);
            }}
          >
            <BiCopy color="#00AEEF" size={24} />
            <span>Duplicate Project</span>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div
            style={{ display: "flex", alignItems: "center", gap: "5px" }}
            onClick={() => this.createMPs(this.props.project)}
          >
            <IoAddCircleOutline color="#00AEEF" size={24} />

            <span>Create MPS</span>
          </div>
        </Menu.Item>
      </Menu>
    );
    return (
      <React.Fragment>
        <TableRow
          hover
          aria-checked={this.props.isItemSelected}
          tabIndex={-1}
          key={this.props.key}
          selected={this.props.isItemSelected}
        >
          <TableCell padding="checkbox">
            <Checkbox
              onClick={(event) =>
                this.props.handleClick(event, this.props.project.id)
              }
              role="checkbox"
              checked={this.props.isItemSelected}
              inputProps={{ "aria-labelledby": this.props.labelId }}
            />
          </TableCell>
          {this.props.headSelected.ID && (
            <TableCell>{this.props.project.id}</TableCell>
          )}
          {this.props.headSelected.Category && (
            <TableCell>
              {this.getActivityName(this.props.project.actid)}
            </TableCell>
          )}
          {this.props.headSelected.ORG && (
            <TableCell>{this.getNGOName(this.props.project.orgid)}</TableCell>
          )}
          {this.props.headSelected.NameAR && (
            <TableCell>{this.props.project.nameAR}</TableCell>
          )}
          {this.props.headSelected.NameEN && (
            <TableCell>{this.props.project.nameEN}</TableCell>
          )}
          {this.props.headSelected.DescriptionAR && (
            <TableCell
              dangerouslySetInnerHTML={{ __html: this.props.project.infoAR }}
            ></TableCell>
          )}
          {this.props.headSelected.DescriptionEN && (
            <TableCell
              dangerouslySetInnerHTML={{ __html: this.props.project.infoEN }}
            ></TableCell>
          )}
          {this.props.headSelected.Order && (
            <TableCell>{this.props.project.Order}</TableCell>
          )}
          {this.props.headSelected.SmartDeeplink && (
            <TableCell>
              {this.props.project.smartDeepLink}
              {this.props.project.smartDeepLink !== "" && (
                <i
                  className="fa fa-clone pointer"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      this.props.project.smartDeepLink
                    );
                  }}
                ></i>
              )}
            </TableCell>
          )}
          {this.props.headSelected.Deeplink && (
            <TableCell>
              {this.props.project.deeplink}

              {this.props.project.deeplink !== "" && (
                <i
                  className="fa fa-clone pointer"
                  onClick={() => {
                    navigator.clipboard.writeText(this.props.project.deeplink);
                  }}
                ></i>
              )}
            </TableCell>
          )}
          {this.props.headSelected.MediaURL && (
            <TableCell>{this.props.project.mediaURL}</TableCell>
          )}
          {this.props.headSelected.Status && (
            <TableCell>
              <Switch
                value={this.props.project.isactive}
                onText={"Active"}
                offText={"OFF"}
                onChange={() => this.toggleActive(this.props.project)}
              />
            </TableCell>
          )}

          {this.props.headSelected.Actions && (
            <TableCell>
              <Dropdown overlay={menu}>
                <div
                  style={{
                    cursor: "pointer",
                    marginLeft: "10px",
                    padding: "14px 0px 0px 14px",
                    textAlign: "start",
                  }}
                >
                  <HiOutlineDotsVertical size={24} />
                </div>
              </Dropdown>
            </TableCell>
          )}
        </TableRow>
      </React.Fragment>
    );
  }
}

const mapDispatchToProp = (dispatch) => ({
  loadProjects: (orgId) => dispatch(loadProjectsList(orgId)),
  editStatus: (data) => dispatch(editProjectStatus(data)),
  duplicate: (project) => dispatch(duplicateProject(project)),
  createMPsss: (project) => dispatch(createMPS(project)),
});

export default connect(
  (state) => ({
    selectedOrg: state.ORGs.selectedOrg,
    projects: state.Projects.projects,
    MPS: state.Projects.mps,
    activities: state.Activities.activities,
    orgs: state.ORGs.orgs,
  }),
  mapDispatchToProp
)(TableWithSwitch);

TableWithSwitch.propTypes = {
  headSelected: PropTypes.object,
  project: PropTypes.object,
  isItemSelected: PropTypes.bool,
  labelId: PropTypes.string,
  handleClick: PropTypes.func,
  duplicate: PropTypes.func,
  editStatus: PropTypes.func,
  loadProjects: PropTypes.func,
  selectedOrg: PropTypes.number,
  activities: PropTypes.array,
  orgs: PropTypes.array,
};
