import React from "react";
import "./banner-info.css";
import { LuClock2 } from "react-icons/lu";
import PropTypes from "prop-types";

export default function BannerInfo({
  title,
  lastUpdatedDate,
  lastUpdatedTime,
  lastAddedDate,
  lastAddedTime,
}) {
  return (
    <>
      <div className="banner-container">
        <h2 className="banner-title">{title}</h2>

        <div className="banner-info-wrapper">
          <div className="info-item">
            <LuClock2 className="info-icon" />
            <div className="info-content">
              <p className="info-label">Last updated</p>
              <p className="info-value">
                {lastUpdatedDate} • {lastUpdatedTime}
              </p>
            </div>
          </div>

          <div className="info-item">
            <LuClock2 className="info-icon" />
            <div className="info-content">
              <p className="info-label">Last Added</p>
              <p className="info-value">
                {lastAddedDate} • {lastAddedTime}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

BannerInfo.propTypes = {
  title: PropTypes.string.isRequired,
  lastUpdatedDate: PropTypes.string.isRequired,
  lastUpdatedTime: PropTypes.string.isRequired,
  updatedBy: PropTypes.string.isRequired,
};
